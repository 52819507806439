﻿$include-dfds-wrapper-classes: true !default;

$wrapper-padding: 0 !default;
$wrapper-medium-padding: ($column-gutter / 2) !default;

@include exports("dfds-wrapper") {
    @if $include-dfds-wrapper-classes {

        .wrapper {

            padding-left: $wrapper-padding;
            padding-right: $wrapper-padding;

            @media #{$medium-up} {

                padding-left: $wrapper-medium-padding;
                padding-right: $wrapper-medium-padding;
            }
        }
    }
}