﻿.travel-container
{
    .label {
        font-size: 16px;
    }

    .word-break {
        word-break: break-word;
    }
}

.attention {
    
    border: 1px solid #FFF6D2;
    background-color: #FFF6D2;
    padding: 1em;
    margin: 0.5em 0 1.5em;
    font-size: 16px;
    
     h4 {

        font-size: 1.1rem;
        margin-bottom: 0.5em;
    }

    a {

        color: #005184;
        text-decoration: underline;
    }
}

#vehicle-tip{
    line-height: 2.5em;
}

#vehicles-content{
    .remove, .add { 
        .label{
            @media #{$small-only} {
                display: none !important;
            }
        }
        
        button{
            padding: 0.35em 0.5em;
            line-height: 1.2em;
        }
    }
}