﻿.tpa-container {

    .flow-link {

        font-family: $font-family-dfds-bold;
        text-transform: uppercase;
    }

    .tpa-category-date {

        position: relative;

        span {

            display: block;
            
            @media #{$medium-up} {

                position:absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .group-panel {

        border: 1px solid #CACACA;
        
        padding-bottom: ($column-gutter / 4);

        .group-header {

            background-color: #e7ecef;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            text-align: left;
        }

        .group-item {

            border-bottom: 1px solid #E5E5E5;
            padding-top: ($column-gutter / 4);
            padding-bottom: ($column-gutter / 2);

            &:last-of-type {

                border-bottom: 0;
                padding-bottom: 0;
            }

            @media #{$medium-up} {

                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .option-name, .option-price, .option-text, .option-radio {

        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .option-price, .option-count {
        
        text-align: right;

        @media #{$medium-up} {

                text-align: left;
        }
    }

    .option-count select {

        width: 4em;

        @media #{$medium-up} {

            width: 100%;
        }
    }
}