﻿@import "components/headers";
@import "components/help";
@import "components/content";
@import "components/itinerary";
@import "components/flow";
@import "components/account";
@import "components/travel";
@import "components/fares";
@import "components/pets";
@import "components/meals";
@import "components/tpa";
@import "components/account";
@import "components/passengers";
@import "components/payment";
@import "components/cabinFares";
@import "components/cabinArrangements";
@import "components/hotels";
@import "components/agent";
@import "components/dynamicFields";

.clear-both {
    clear: both;
}