﻿$include-dfds-panels-classes: true !default;

$dfds-panel-border-size: 1px !default;
$dfds-panel-border-type: solid !default;
$dfds-panel-border-color: #CACACA !default;

$dfds-panel-margin-bottom: 0.5em !default;

$dfds-panel-header-bg: #E7ECEF !default;
$dfds-panel-header-padding: 1em !default;

$dfds-panel-collapsed-border-color: #D0D0D0 !default;
$dfds-panel-collapsed-header-bg: #F7F7F7 !default;

@include exports("dfds-panels") {
    @if $include-dfds-panels-classes {
        
        .panel {

            border: $dfds-panel-border-size $dfds-panel-border-type $dfds-panel-border-color;
            margin-bottom: $dfds-panel-margin-bottom;
            

            &:last-child {

                margin-bottom: 0;
            }

            .panel-header {

                padding-top: ($column-gutter/2);
                padding-bottom: ($column-gutter/2);
                background-color: $dfds-panel-header-bg;
                font-family: $font-family-dfds-bold;
            }

            &.expandable .panel-header {

                cursor: pointer;
            }

            &.collapsed:not(.uncollapsed) {
            
                border-color: $dfds-panel-collapsed-border-color;
                box-shadow: none;

                .panel-header {
        
                    background-color: $dfds-panel-collapsed-header-bg;
                }

                .panel-content {

                    display: none;
                }
            }

            &.collapsed.selected, 
            &.uncollapsed.selected {

                border-color: $dfds-color-brand;
                

                .panel-header {
        
                    background-color: $dfds-color-brand;
                    color: $dfds-color-primary;
                }

                .panel-content {

                    display: block;
                }
            }

            .panel-content {

            }
        }
    }
}