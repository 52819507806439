$include-dfds-global-classes: true !default;

$dfds-color-primary: #ffffff !default;
$dfds-color-secondary: #eef0f1 !default;
$dfds-color-brand: #005184 !default;
$dfds-color-brand-hover: darken($dfds-color-brand, 10) !default;
$dfds-color-brand-light: #b3cbda !default;
$dfds-color-outline: #ddd !default;
$dfds-color-border: #d3d4d4 !default;
$dfds-color-action: #ed8b00 !default;
$dfds-color-action-hover: #cc6600 !default;
$dfds-color-text: #4d4e4c !default;
$dfds-color-text-light: #6d6f71 !default;

$body-min-width: 320px !default;

@include exports("dfds-global") {
    @if $include-dfds-global-classes {
        *,
        *:before,
        *:after {
            @include box-sizing(border-box);
        }

        html,
        body {
            font-size: $font-size-base;
        }

        html {
            height: 100%;
            margin: 0;
            padding: 0;
        }

        body {
            height: auto;
            min-height: 100%;
            font-family: $font-family-dfds-regular;
            font-weight: $font-weight-normal;
            color: $dfds-color-text;
            background-color: #eef0f1;
            min-width: $body-min-width;
            -webkit-font-smoothing: antialiased;
            overflow-y: scroll;
        }

        a {
            text-decoration: none;
            color: $dfds-color-brand;

            &:visited {
                color: $dfds-color-brand;
            }

            &:hover {
                color: $dfds-color-brand-hover;
                text-decoration: none;
            }

            &.tel {
                color: $dfds-color-text;

                &:visited {
                    color: $dfds-color-text;
                }

                &:hover {
                    color: $dfds-color-text;
                }
            }
        }

        strong {
            font-weight: normal;
            font-family: $font-family-dfds-bold;
        }

        .row {
            position: relative;
        }

        .left {
            float: left;
        }

        .right {
            float: right;
        }

        .text-left {
            text-align: left;
        }

        .text-center {
            text-align: center;
        }

        .text-right {
            text-align: right;
        }

        .text-justify {
            text-align: justify;
        }

        .block {
            display: block;
        }

        .center {
            margin: 0 auto;
        }

        .hide {
            display: none !important;
        }

        .show {
            display: inherit !important;
        }

        .quarter-gutter {
            margin-bottom: ($column-gutter/4);
        }

        .half-gutter {
            margin-bottom: ($column-gutter/2);
        }

        .gutter {
            margin-bottom: $column-gutter;
        }

        .quarter-gutter-top {
            margin-top: ($column-gutter/4);
        }

        .half-gutter-top {
            margin-top: ($column-gutter/2);
        }

        .gutter-top {
            margin-top: $column-gutter;
        }
    }
}
