﻿$include-dfds-validation-classes: true !default;

@include exports("dfds-validation") {
    @if $include-dfds-validation-classes {

        .field-validation-error {

            color: #CD2F2F;
            padding: 0.45em 0.5em;
            display: inline-block;
            border: 1px solid #EACECE;
            background-color: #FFF3F3;
            width: 100%;
        }

        .field-validation-ok {

            color: Green;
            padding: 0.45em 0.5em;
            display: inline-block;
            border: 1px solid #CEEACE;
            background-color: #F3FFF3;
            width: 100%;
        }

        .field-validation-note {
            color: black;
            padding: 0.45em 0.5em;
            display: inline-block;
            border: 1px solid #FFD597;
            background-color: #FFEDD5;
            border-top: none;
            width: 100%;
          }

        .validation-summary-valid {
            ul {
                margin: 0;
            }
        }
    }
}
