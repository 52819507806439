﻿

.sbw_agentHome {

    .agent-button {

        border-bottom: 1px solid #EDEDED;
        padding-bottom: 1.6em;
        margin-bottom: 1.6em;
    }

    .button-gutter {

        padding-bottom: 1.5em;

        @media #{$large-up} {
            padding-bottom: 0;
        }

    }

    .group-panel {

        border: 1px solid transparent;
        padding-bottom: ($column-gutter / 2);

        @media #{$large-up} {

            border: 1px solid #CACACA;
            
        }

        .group-header {
            
            display: none;
            background-color: #e7ecef;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            text-align: left;

            @media #{$large-up} {

                display: block;
            }
        }

        .group-item {

            &.preliminary {
                color: #D0D0D0;
            }
            margin-bottom: 1em;
            border: 1px solid #E5E5E5;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            cursor: pointer;

            @media #{$large-up} {

                border: 0;
                margin-bottom: 0;
                
                &:last-of-type {

                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }

            &:hover {

                background-color: #E8E8E8;
            }
        }
    }

    .controls {

        width: 100%;
        display: inline-block;
        padding: 1rem;
        font-size: 0.9em;
        color: #005184;
        text-transform: uppercase;
        font-family: $font-family-dfds-bold;
        line-height: 1em;
        margin-bottom: 1em;

        a {

            cursor: pointer;

            &:before, &:after {

                font-size: 0.75em;
            }

            &.next {

                margin-left: ($column-gutter/2);
            }

            &.previous {
                    
                margin-right: ($column-gutter/2);
            }

            &.disabled {

                opacity: 0.5;
                cursor: default;
            }
        }

        .buttons, .count {
            
            display: block;
            margin: 0 auto;
            text-align: center;
        }
        
        .buttons {

            margin-top: 1em;
        }

        .next, .previous {

            display: inline-block;
        }

        @media #{$medium-up} {

            .buttons {

                margin-top: 0;
                float: right;
                display: inline-block;
            }

            .count {

                float: left;
                display: inline-block;
            }
        }
    }

    .option-text, .option-count {

        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .option-text {

        font-weight: bold;
    }

    .option-count {
        
        text-align: right;

        @media #{$medium-up} {

            text-align: left;
        }
    }

    .agent_profile_label {
        font-weight: bold;
    }
}
@media #{$large-up} {

    .sbw_agentHome {

        padding-bottom: 1.6em;

        .right-border {

            border-right: 1px solid #EDEDED;
        }

        .agent-button {

            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.radio-button-gutter {
    margin-bottom: 0.9rem;
    display: inline-block;
}

@media #{$large-up} {

    .radio-button-gutter {
        margin-bottom: 0;
    }
}