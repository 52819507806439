// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

@import "../functions";
//
// Foundation Variables
//

// Data attribute namespace
// styles get applied to [data-mysite-plugin], etc
$namespace: false !default;

// The default font-size is set to 100% of the browser style sheet (usually 16px)
// for compatibility with browser-based text zoom or user-set defaults.

// Since the typical default browser font-size is 16px, that makes the calculation for grid size.
// If you want your base font-size to be different and not have it affect the grid breakpoints,
// set $rem-base to $base-font-size and make sure $base-font-size is a px value.
$base-font-size: 100% !default;

// $base-line-height is 24px while $base-font-size is 16px
$base-line-height: 1.5 !default;

$white         : #FFFFFF !default;
$ghost         : #FAFAFA !default;
$snow          : #F9F9F9 !default;
$vapor         : #F6F6F6 !default;
$white-smoke   : #F5F5F5 !default;
$silver        : #EFEFEF !default;
$smoke         : #EEEEEE !default;
$gainsboro     : #DDDDDD !default;
$iron          : #CCCCCC !default;
$base          : #AAAAAA !default;
$aluminum      : #999999 !default;
$jumbo         : #888888 !default;
$monsoon       : #777777 !default;
$steel         : #666666 !default;
$charcoal      : #555555 !default;
$tuatara       : #444444 !default;
$oil           : #333333 !default;
$jet           : #222222 !default;
$black         : #000000 !default;

// We use these as default colors throughout
$primary-color: #008CBA !default;   // bondi-blue
$secondary-color: #e7e7e7 !default; // white-lilac
$alert-color: #f04124 !default;     // cinnabar
$success-color: #43AC6A !default;   // sea-green
$warning-color: #f08a24 !default;   // carrot
$info-color: #a0d3e8 !default;      // cornflower

// We use these to define default font stacks
$font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;
$font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif !default;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace !default;

// We use these to define default font weights
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

// We use these to control various global styles
$body-bg: #fff !default;
$body-font-color: #222 !default;
$body-font-family: $font-family-sans-serif !default;
$body-font-weight: $font-weight-normal !default;
$body-font-style: normal !default;

// We use this to control font-smoothing
$font-smoothing: antialiased !default;

// We use these to control text direction settings
$text-direction: ltr !default;
$default-float: left !default;
$opposite-direction: right !default;
@if $text-direction == ltr {
  $default-float: left;
  $opposite-direction: right;
} @else {
  $default-float: right;
  $opposite-direction: left;
}

// We use these to make sure border radius matches unless we want it different.
$global-radius: 3px !default;
$global-rounded: 1000px !default;

// We use these to control inset shadow shiny edges and depressions.
$shiny-edge-size: 0 1px 0 !default;
$shiny-edge-color: rgba(#fff, .5) !default;
$shiny-edge-active-color: rgba(#000, .2) !default;

//We use this as cursors values for enabling the option of having custom cursors in the whole site's stylesheet
$cursor-auto-value: auto !default;
$cursor-crosshair-value: crosshair !default;
$cursor-default-value: default !default;
$cursor-pointer-value: pointer !default;
$cursor-help-value: help !default;
$cursor-text-value: text !default;

@include exports("global") {

  // Meta styles are included in all builds, as they are a dependancy of the Javascript.
  // Used to provide media query values for javascript components.
  // Forward slash placed around everything to convince PhantomJS to read the value.

  meta.foundation-version {
    font-family: "/{{VERSION}}/";
  }

  meta.foundation-mq-small {
    font-family: "/" + unquote($small-up) + "/";
    width: lower-bound($small-range);
  }

  meta.foundation-mq-small-only {
    font-family: "/" + unquote($small-only) + "/";
    width: lower-bound($small-range);
  }

  meta.foundation-mq-medium {
    font-family: "/" + unquote($medium-up) + "/";
    width: lower-bound($medium-range);
  }

  meta.foundation-mq-medium-only {
    font-family: "/" + unquote($medium-only) + "/";
    width: lower-bound($medium-range);
  }

  meta.foundation-mq-large {
    font-family: "/" + unquote($large-up) + "/";
    width: lower-bound($large-range);
  }

  meta.foundation-mq-large-only {
    font-family: "/" + unquote($large-only) + "/";
    width: lower-bound($large-range);
  }

  meta.foundation-mq-xlarge {
    font-family: "/" + unquote($xlarge-up) + "/";
    width: lower-bound($xlarge-range);
  }

  meta.foundation-mq-xlarge-only {
    font-family: "/" + unquote($xlarge-only) + "/";
    width: lower-bound($xlarge-range);
  }

  meta.foundation-mq-xxlarge {
    font-family: "/" + unquote($xxlarge-up) + "/";
    width: lower-bound($xxlarge-range);
  }

  meta.foundation-data-attribute-namespace {
    font-family: #{$namespace};
  }

  @if $include-html-global-classes {

    // Must be 100% for off canvas to work
    html, body { height: 100%; }

    // Set box-sizing globally to handle padding and border widths
    *,
    *:before,
    *:after {
      @include box-sizing(border-box);
    }

    html,
    body { font-size: $base-font-size; }

    // Default body styles
    body {
      background: $body-bg;
      color: $body-font-color;
      padding: 0;
      margin: 0;
      font-family: $body-font-family;
      font-weight: $body-font-weight;
      font-style: $body-font-style;
      line-height: $base-line-height; // Set to $base-line-height to take on browser default of 150%
      position: relative;
      cursor: $cursor-auto-value;
    }

  a:hover { cursor: $cursor-pointer-value; }

    // Grid Defaults to get images and embeds to work properly
    img { max-width: 100%; height: auto; }

    img { -ms-interpolation-mode: bicubic; }

    #map_canvas,
    .map_canvas {
      img,
      embed,
      object { max-width: none !important;
      }
    }

    // Miscellaneous useful HTML classes
    .left { float: left !important; }
    .right { float: right !important; }
    .clearfix { @include clearfix; }

    // Hide visually and from screen readers
    .hide {
      display: none !important;
      visibility: hidden;
    }

    // Hide visually and from screen readers, but maintain layout
    .invisible { visibility: hidden; }

    // Font smoothing
    // Antialiased font smoothing works best for light text on a dark background.
    // Apply to single elements instead of globally to body.
    // Note this only applies to webkit-based desktop browsers and Firefox 25 (and later) on the Mac.
    .antialiased { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

    // Get rid of gap under images by making them display: inline-block; by default
    img {
      display: inline-block;
      vertical-align: middle;
    }

    //
    // Global resets for forms
    //

    // Make sure textarea takes on height automatically
    textarea { height: auto; min-height: 50px; }

    // Make select elements 100% width by default
    select { width: 100%; }
  }
}
