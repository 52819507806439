/* You can add global styles to this file, and also import other style files */

body {
    font-size: 16px;
    height: auto;
    min-height: 100%;
    font-weight: normal;
    color: #4d4e4c;
    min-width: 320px;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    overflow-y: scroll;
}

.p-dialog {
  border-radius: 0;
}

.p-dialog-header {
  padding: 16px !important;
}

.p-dialog-content {
  padding: 16px !important;
  padding-top: 0 !important;
  font-family: 'DFDS';
}

.p-dialog-title {
  font-family: 'DFDS';
  font-weight: 700;
  color: rgb(0, 0, 30);
}

body.p-overflow-hidden {
  overflow-y: scroll;
}

body.Modal__Body--open {
    padding-right: 0 !important;
}

button[aria-label="Close"] {
    background: transparent;
}

@media only screen and (max-width: 480px) {
    body.popup-open {
        overflow: hidden;
    }
}

h2 {
    margin-bottom: 0.5em;
    margin-top: 1em;
}

.gutter {
    margin-bottom: 1.7em;
}

.half-gutter {
    margin-bottom: 0.5em;
}

.quater-gutter {
    margin-bottom: 0.25em;
}

.small-gutter {
    padding-bottom: 1rem;
}

.small-gutter-top {
    margin-top: 1rem;
}

.medium-gutter-top {
    margin-top: 2rem;
}

::-webkit-input-placeholder {
    /* Chrome */
    color: #7d7d7d;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: #7d7d7d;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #7d7d7d;
    opacity: 1;
}
:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #7d7d7d;
    opacity: 1;
}

.loading-filler {
    min-height: 10em;
}

.superscript {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}

.error-border input,
.error-border select {
    border: 1px solid #be1e2d;
}

/* this was in old mvc css accomplished by .row .row */
.no-column-margin,
.row.no-column-margin {
    width: auto;
    margin-left: -0.83333rem;
    margin-right: -0.83333rem;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
}
@media only screen and (max-width: 40.063em) {
    .row .row.no-margin-mobile,
    .row.no-margin-mobile {
        margin: 0;
    }
    .column.no-padding-mobile {
        padding: 0;
    }
    .padding-mobile {
        padding-left: 0.83333rem;
        padding-right: 0.83333rem;
    }
}

.sbw-logo {
    height: 2rem;
}
