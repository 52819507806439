@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.cabin-fares-container {

    .leg-container {

        .leg-header {
            position: relative;

            .leg-ferry {
                color: #005184;
                display: inline-block;
                font-family: $font-family-dfds-bold;
            }

            .is-loading.active:before {
                background-position: center 6em;
            }

            /* only used for MVC */
            > div.old-date-navigation {
                background-color: #EDF4FB;
                width: 100%;
                display: inline-block;
                padding: 0.5em 0.5em 0.35em 0.5em;
                font-size: 0.9em;
                color: #005184;
                text-transform: uppercase;
                font-family: $font-family-dfds-bold;
                line-height: 1em;
                margin-bottom: 1em;

                @media #{$medium-up} {
                    width: auto;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    margin-bottom: 0;
                }

                a {

                    &:before, &:after {
                        font-size: 0.75em;
                    }

                    &.next {
                        margin-left: ($column-gutter/2);
                        float: right;

                        @media #{$medium-up} {
                            float: none;
                        }
                    }

                    &.previous {
                        margin-right: ($column-gutter/2);
                    }
                }
            }
        }

        .group-panel {
            padding-bottom: ($column-gutter / 2);

            .group-header {
                background-color: #e7ecef;
                font-weight: bold;
                padding-bottom: ($column-gutter / 2);
                padding-top: ($column-gutter / 2);
                text-align: left;
            }

            .group-item {
                padding-top: ($column-gutter / 2);
                padding-bottom: ($column-gutter / 2);

                &:last-of-type {
                    border-bottom: 0;
                    padding-bottom: 0;
                }

                @media #{$medium-up} {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        .cabin-panel {
            padding-bottom: 0;

            .group-header {
                padding-bottom: 0;
                padding-top: 0;
            }

            .route-header {
                font-size: 1rem;
                padding-bottom: 0.4rem;
                padding-top: ($column-gutter / 2);

                @media #{$medium-up} {
                    padding-top: 0.4rem;
                }
            }

            .dates-header {
                overflow: auto;
                padding-right: 0;

                label {
                    display: none;
                    float: left;
                    text-align: center;
                    border-left: 1px solid #C8CFD2;
                    padding-bottom: 0.6rem;
                    padding-top: 0.6rem;
                    cursor: pointer;

                    @media #{$large-up} {
                        display: block;
                    }

                    &:hover {
                        background-color: #F3F7F9;
                    }

                    &.selected {
                        display: block;
                        background-color: #D4DADD !important;
                    }

                    span {
                        display: block;
                        font-weight: normal;
                    }
                }
            }


            .cabin-item:before, .cabin-item:after {
                content: "";
                clear: none !important;
                display: none !important;
            }

            .cabin-item {
                padding-bottom: 0;
                padding-top: 0;
                @include flexbox();
                overflow: hidden;

                .column {

                    @include flexbox();
                    align-items: center;
                }

                &:nth-of-type(odd) {
                    background-color: #F9FAFA;
                }

                .cabin-name {
                    padding-right: 0;
                    padding-bottom: 0.6rem;
                    padding-top: 0.6rem;

                    .name {
                        width: 70%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    .users {
                        display: inline-block;
                        width: 26%;
                        text-align: right;
                        vertical-align: top;
                        color: #788288;

                        &:before {
                            margin-right: 0;
                        }

                        @media #{$medium-up} {
                            float: right;
                        }

                        i {
                            font-style: normal;
                        }
                    }
                }

                .cabin-prices {
                    padding-right: 0;
                    overflow: auto;

                    sup {
                        display: none;
                    }

                    label {
                        cursor: pointer;
                        display: none;
                        float: left;
                        text-align: center;
                        padding: 0.6rem 0.3em;
                        border-left: 1px solid #E7ECEF;
                        border-top: 1px solid rgba(0, 0, 0, 0);

                        @media #{$large-up} {
                            display: block;
                        }

                        &.ellipsis {
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        &.selected-column {
                            display: block;
                            background-color: #EDF4FB;
                            border-top: 1px solid #E7ECEF;

                            sup, select {
                                display: inline-block;
                            }

                            &.selected-cell {
                                background-color: #f6861f;
                                span, sup {
                                    color: #fff;
                                    font-family: $font-family-dfds-bold;
                                }
                            }
                        }

                        select {
                            display: none;
                            padding: 0.3em 1.4em 0.1em 0.2em;
                            width: auto;
                            background-size: 1em;
                            background-position: right 0.2em center;
                            margin-left: 0.3em;
                        }
                    }

                    .selected-price {
                        font-weight: bold;
                    }

                    .suppressed{
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
}
