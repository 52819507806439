﻿$include-dfds-forms-classes: true !default;

@mixin form-field-style {
    width: 100%;
    padding: 0.6em 0.5em;
    border: 1px solid #b8b8b7;
    border-radius: 2px;
    background: #FCFCFC;


    &:focus {
        outline: none;
        border: 1px solid #90B3C9;

    }
}

@include exports("dfds-forms") {
    @if $include-dfds-forms-classes {

        .label {
            margin-bottom: 0.3em;

            label {
            }
        }

        .field {
            margin-bottom: 1em;

            &.submit {
                margin-top: 0.5em;
            }
        }

        input, .input-field {

            @include form-field-style;

            &[type=submit], &[type=button], &button {
                border: 1px solid #b8b8b7;
                padding: 0.4em .5em;
                background: #F2F2F2;


                &:hover, &:focus {

                }
            }

            &[type=radio]:focus, &[type=checkbox]:focus {
                & + label:before {
                    border: 1px solid #90B3C9;
                }
            }

            &[type=radio]:focus + [type=hidden], &[type=checkbox]:focus + [type=hidden] {
                & + label:before {
                    border: 1px solid #90B3C9;
                }
            }

            &[type=radio], &[type=checkbox] {
                position: fixed;
                top: -42px;
                left: -42px;
                @include ie8-hack(display, inline-block);

                & + input[type=hidden] + label,
                & + label {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    margin-right: 1em;
                    padding-left: 2em;
                    min-height: 1em;

                    &:before {
                        content: "";
                        display: inline-block;
                        @include ie8-hack(display, none);
                        padding: 0.7em 0.7em;
                        border: 1px solid #b8b8b7;
                        border-radius: 2px;
                        background: #FCFCFC;

                        vertical-align: middle;
                        position: absolute;
                        left: 0;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0.45em;
                        top: 0.45em;
                        visibility: hidden;
                        border-radius: 25%;
                        border: 0.31em solid #5382A0;
                        @include ie8-hack(display, none);
                    }
                }

                &:checked + input[type=hidden] + label:after,
                &:checked + label:after {
                    visibility: visible;
                }
            }

            &[type=radio] + label {

                &:before, &:after {
                    border-radius: 100%;
                }
            }

            &.date-input, &.date {
                -webkit-appearance: none;

                &::-ms-expand {
                    display: none;
                }

                cursor: pointer;
                /*background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAABvAAAAbwHxotxDAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAA9QTFRF////AAAAAAAAAAAAAAAAUTtq8AAAAAR0Uk5TALS4/gfdy/0AAAA2SURBVAhbY2BgUmAAYwYWBwh2EVJ0AWEGF0NhFxBmcIECBqAsCLAwOLBAIBKDWClUBkwKZgUAP9wPAyxoZpYAAAAASUVORK5CYII3f4ead5d8a05f64e31751f6eb6e4fe15');*/
                background-image: url(../images/icon-calendar.png);
                background-repeat: no-repeat;
                background-position: right 0.5em center;
                background-size: 16px; // Maybe also EM?
            }

            &[disabled] {
                background-color: #E7E7E7;
                color: #5C5C5C;
            }
        }

        .coi__checkbox {
            // Can't override them without important
            top: 0 !important;
            left: 0 !important;
        }

        textarea {

            @include form-field-style;
        }

        .call-to-action input {
            border: 2px solid #68b2e3;
        }

        .call-to-action select {
            border: 2px solid #68b2e3;
        }

        select {

            @include form-field-style;
            padding: 0.45em .5em;
            // This is to remove the default arrow in selects
            -webkit-appearance: none;

            &::-ms-expand {
                display: none;
            }
            // Workaround to hide dropdown arrow in Firefox: https://gist.github.com/joaocunha/6273016
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';
            background-image: url('/_ng/booking/assets/narrow-chevron-down-blue.svg');
            background-repeat: no-repeat;
            background-position: right 0.8em center;
            background-size: 15px; // Maybe also EM?
            &[disabled] {
                background-color: #FFF;
                color: #FFF;
            }
        }

        .inline {

            .label {
                font-size: 1em !important; /* This is to keep it same height as field */
                label {
                    width: 100%;
                    padding: 0.35em 0.5em;
                    border: 1px solid #b8b8b7;
                    background: #EFF2F4;
                    display: inline-block;
                    border-right: 0;
                    border-radius: 2px 0 0 2px;
                }

                input {
                    padding: 0.35em 0.5em;
                    border-right: 0;
                    border-radius: 2px 0 0 2px;
                }
            }

            .field {

                input, textarea, select {
                    border-radius: 0 2px 2px 0;
                }
            }
        }

        .inline-right {

            .label {
                font-size: 1em !important; /* This is to keep it same height as field */
                label {
                    width: 100%;
                    padding: 0.35em 0.5em;
                    border: 1px solid #b8b8b7;
                    background: #EFF2F4;
                    display: inline-block;
                    border-left: 0;
                    border-radius: 0 2px 2px 0;
                    text-align: right;
                }

                input {
                    padding: 0.35em 0.5em;
                    border-left: 0;
                    border-radius: 0 2px 2px 0;
                }

                @media only screen and (max-width: 40em) {
                    input {
                        border-left: 1px solid rgb(204, 204, 204);
                    }
                }
            }

            .field {

                input, textarea {
                    border-radius: 2px 0 0 2px;
                }

                select {
                    border-radius: 2px 0 0 2px;
                    padding: 0.27em 0.5em;
                }

                /* Only target firefox browser */
                @-moz-document url-prefix() {
                    select {
                        padding: 0.30em 0.5em;
                    }

                    @media only screen and (min-width: 54.063em) and (max-width: 64.063em) {
                        select {
                            padding: 0.29em 0.5em !important;
                        }
                    }

                    @media only screen and (max-width: 480px) {
                        select {
                            padding: 0.29em 0.5em;
                        }
                    }
                }

                @media only screen and (min-width: 40.063em) and (max-width: 64.063em) {
                    select {
                        padding: 0.30em 0.5em;
                    }
                }

                @media only screen and (max-width: 480px) {
                    select {
                        padding: 0.29em 0.5em;
                    }
                }
            }
        }
    }
}
