﻿$include-dfds-utilities-viewport-classes: false !default;

@include exports("dfds-utilities-viewport") {
    @if $include-dfds-utilities-viewport-classes {

        // Viewport label
        // Only for development purpose
        .viewport-label {

            position: fixed;
            width: 70px;
            height: 22px;
            line-height: 20px;
            font-weight: 300;
            text-align: center;
            bottom: 20px;
            right: 50px;
            z-index: 100;
            font-size: 11px;
            background-color: white; 
            color: #6d6f71;
            text-transform: uppercase;
            border: 1px solid #C5D1DA;
            border-right: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        .viewport-label:after {

            position: absolute;
            display: block;
            font-weight: bold;
            width: 30px;
            height: 22px;
            line-height: 22px;
            top: -1px;
            bottom: 0;
            right: -30px;
            text-align: center;
            background-color: #C5D1DA;
            color: #6d6f71;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            content: 'S';

            @media #{$medium-up} {

                content: "M";
            }

            @media #{$large-up} {

                content: "L";
            }

            @media #{$xlarge-up} {

                content: "XL";
            }

            @media #{$xxlarge-up} {

                content: "XXL";
            }
        }
    }
}