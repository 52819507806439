﻿@mixin ie8-hack($property, $value) {
    @media \0screen { 
        #{$property}: $value
    }
}

// @mixins
//
// We use this to make an element a locked width
// $media determines the mediaquery and $size is the width
@mixin locked-size($size:auto, $gutter-width:0, $mediaquery:none, $media:none) {

    @if $mediaquery != none {

        @media #{$mediaquery} {

            &.#{$media}-locked-width {

                width: $size;
        
                &.right {

                    right: 0;
                    margin-left: -$size;
                    padding-left: $gutter-width;
                }

                &.left {

                    left: 0;
                    margin-right: -$size;
                    padding-right: $gutter-width;
                }
            }

            &.#{$media}-locked-beside {

                &.right {

                    padding-left: $size;
                }

                &.left {

                    padding-right: $size;
                }
            }
        }

    } @else {

        &.locked-width {

            width: $size;
        
            &.right {

                right: 0;
                margin-left: -$size;
            }

            &.left {

                left: 0;
                margin-right: -$size;
            }
        }

        &.locked-beside {

            &.right {

                padding-left: $size;
            }

            &.left {

                padding-right: $size;
            }
        }

    }
}

// @mixins
//
// This is for creating a triangle shape
@mixin dfds-triangle($color:#ffffff, $size:1em) {

    position: relative;

    &:after {

        content: "";
        display: inline-block;
        position: absolute;
        top: 0;

        border-top: 0 solid transparent;
        border-bottom: $size solid transparent;
        border-left: ($size - 1) solid $color;
        right: -($size - 1.039); // this could be better
    }
}