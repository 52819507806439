﻿.pets-container {

    .no-result {

        text-align: center;

        h1, h2 {

            font-size: 1em;
            text-transform: capitalize;
        }
    }

    .pets-header {

        position: relative;

        span {

            display: block;
            
            @media #{$medium-up} {

                position:absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .group-panel {

        border: 1px solid #CACACA;
        
        padding-bottom: ($column-gutter / 2);

        .group-header {

            background-color: #e7ecef;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            text-align: left;
        }

        .group-item {

            border-bottom: 1px solid #E5E5E5;
            padding-top: ($column-gutter / 2);
            padding-bottom: ($column-gutter / 2);

            &:last-of-type {

                border-bottom: 0;
                padding-bottom: 0;
            }

            @media #{$medium-up} {

                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .option-count {
        
        text-align: right;

        @media #{$medium-up} {

                text-align: left;
        }
    }

    .option-count select {

        width: 6em;

        @media #{$medium-up} {

            width: 100%;
        }
    }
}