﻿
.sticky-wrap {

    @media #{$large-up} {

        position: static;

        .sticky {
            z-index: 1;
            width: 355px;

            &.sticky-top {

                position: fixed;
                top: 5px;
                bottom: auto;
            }

            &.sticky-bottom {

                position: absolute;
                top: auto;
                bottom: 0;
            }
        }
    }
}

.itinerary {

    padding-left: 0;
    padding-right: 0;

    @media #{$medium-up} {

        padding-left: ($column-gutter / 2);
        padding-right: ($column-gutter / 2);
    }

    .date::first-letter {
        text-transform: capitalize
    }

    .container {

        padding-top: 1.4em;
    }

    .total-price {
        display: table;
        width: 100%;
        margin-bottom: 0;
        list-style:none;
        background-color: $dfds-color-brand;
        color: $dfds-color-primary;
        padding: 0.5em ($column-gutter / 2);
        overflow: hidden;
        font-family: $font-family-dfds-bold;
        font-size: $font-size-base;

        .total, .up-front, .remaining, .quote-campaign{
            display:table-row;
        }

        .currency{
            margin-right: 0.2em;
            width:100%;
            display: table-cell;
            text-align: right;
            padding-right: 0.25em;
        }

        .amount{
            display: table-cell;
            text-align: right;
        }

        .total.deposit-selected{
            .label, .currency, .amount{
                font-family: 'DFDS-light', 'Segoe UI';
                border-bottom: 1px solid $dfds-color-primary;
                padding-bottom: 0.5rem;
            }
        }

        .quote-deposit:nth-of-type(2){
            .label, .currency, .amount{
                padding-top: 0.5rem;
            }
        }

        .quote-deposit{
            &.up-front{
                font-family: 'DFDS-bold', 'Segoe UI';
            }
            &.remaining{
                font-family: 'DFDS-light', 'Segoe UI';
            }
        }

        .label {
            display:table-cell;
            white-space: nowrap;
            margin: 0;
            vertical-align: middle;

            @media #{$medium-only} {
                width: 200px;
            }
        }
    }

    .plan {
        margin-bottom: ($column-gutter / 2);
        padding-top: ($column-gutter / 2);
        border-top: 1px solid $dfds-color-outline;

        &:first-child {

            padding-top: 0;
            border-top: none;
        }

        .count {
            font-weight: bold;
        }

        .label {

            display: inline-block;

            @media #{$large-up} {

                margin-bottom: 0;
                width: auto;
            }
        }

        i {

            font-style: normal;
        }

        .inline, .block {

            > span {

                display: inline-block;
                margin-top: 0.3em;
                vertical-align: top;
                float: left;
            }
        }

        .header {

            margin-bottom: 0.6em;

            .label {

                font-family: $font-family-dfds-light;
                font-size: 0.85rem;
                color: $dfds-color-text-light;
                margin-bottom: 0.3em;
                margin-top: 0;

                @media #{$small-only} {

                    display: block;
                    width: auto;
                }

                @media #{$large-up} {

                    display: block;
                    width: auto;
                }
            }

            span {

                font-family: $font-family-dfds-bold;
                font-size: $font-size-bigger;
            }
        }

        .passengers {

            span:before {

                content: ",\00a0";
                display: inline-block;
            }

            span:first-child:before {

                display: none;
            }
        }

        .price-items {

            display: block;
            margin-top: 0;

            @media #{$medium-up} {

                display: block;
                margin-top: 0.3em;
            }

            @media #{$large-up} {

                margin-top: 0;
                display: block;
            }

            .price-item {

                display: block;
                overflow: hidden;
                margin-top: 0.3em;
                position: relative;
                width: 100%;

                @media #{$medium-up} {

                    margin-top: 0;
                }

                > span.label {

                    @media #{$large-up} {

                        display: inline-block;
                        width: 75%;
                    }
                }

                .price {

                    display: inline-block;
                    text-align: right;
                    float: right;

                    @media #{$medium-only} {
                        margin-left: 0.5em;
                    }

                    @media #{$large-up} {

                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        height: 1em;
                        line-height: 1em;
                    }
                }
            }
        }
    }
}
