﻿.payment-container {
    .payment-form {
        padding: $column-gutter;
        margin-bottom: ($column-gutter/2);
        background-color: #EDF5FC;
        p {
            margin-bottom: 0;
        }
    }
    // Specific styles for altapay form
    .pensio_payment_form_row:before,
    .pensio_payment_form_row:after {
        content: " ";
        display: table;
    }
    .pensio_payment_form_row:after {
        clear: both;
    }
    .pensio_payment_form_outer {
        position: relative;
    }
    .pensio_payment_form_row {
        width: auto;
        margin-left: -($column-gutter/2);
        margin-right: -($column-gutter/2);
        margin-top: 0;
        margin-bottom: 0;
        max-width: none;
        position: relative;
    }
    .pensio_payment_form_label_cell,
    .cvc_description_row>div {
        position: relative;
        padding-left: ($column-gutter/2);
        padding-right: ($column-gutter/2);
        float: left;
        display: block;
        width: 100%;
        margin-bottom: 0.3em;
        padding-top: 0;
        @media #{$medium-up} {
            width: 41.66667%;
            padding-top: 0.3em;
        }
        @media #{$large-up} {
            width: 33.33333%;
        }
        @media #{$xlarge-up} {
            width: 25%;
        }
    }
    .cvc_description_row .pensio_payment_form_cvc_cell {
        padding-top: 0;
    }
    .pensio_payment_form_cvc_error {
        margin-bottom: 0;
    }
    .pensio_payment_form_input_cell {
        position: relative;
        padding-left: ($column-gutter/2);
        padding-right: ($column-gutter/2);
        float: left;
        width: 100%;
        margin-bottom: 1em;
        @media #{$medium-up} {
            width: 58.33333%;
        }
        @media #{$large-up} {
            width: 66.66667%;
        }
        @media #{$xlarge-up} {
            width: 50%;
        }
        img {
            position: absolute;
            right: 1.6rem;
            top: 0.6rem;
        }
    }
    .pensio_payment_form_input_cell,
    .pensio_payment_form_outer {
        input {
            width: 100%;
        }
        select {
            width: 48%;
            @media #{$medium-up} {
                width: 35%;
            }
        }
        #cvcInput {
            width: 100%;
            @media #{$medium-up} {
                width: 35%;
            }
        }
    }
    #idealIssuer {
        width: 15em;
    }
    .pensio_payment_form_input_cell div.BankCodePopup {
        left: 0;
        z-index: 999;
        img {
            position: static;
        }
    }
    .pensio_payment_form_row.expiry_row .pensio_payment_form_label_cell[for="emonth"] {
        @media #{$small-only} {
            width: 50%;
        }
    }
    .pensio_payment_form_row .pensio_payment_form_label_cell[for="eyear"] {
        @media #{$small-only} {
            width: 48%;
            padding-left: 0;
        }
    }
    .pensio_payment_form_row.expiry_row {
        visibility: hidden;
        .pensio_payment_form_label_cell {
            visibility: visible;
        }
        .pensio_payment_form_input_cell {
            visibility: visible;
        }
    }
    .pensio_payment_form_row br {
        display: none;
    }
    .pensio_payment_form_row.expiry_row> :not(:first-child):not(:last-child) {
        @media #{$medium-up} {
            display: none;
        }
    }
    .pensio_payment_form_cvc_cell img {
        max-width: 20%;
        @media #{$medium-up} {
            width: 15%;
        }
    }
    .cvc_description_row {
        &>div {
            color: #7E7E7E;
            width: 100%;
            margin-left: 0;
            right: auto;
            @media #{$medium-up} {
                width: 58.33333%;
                margin-left: 41.66667%;
            }
            @media #{$large-up} {
                width: 66.66667%;
                margin-left: 33.33333%;
            }
            @media #{$xlarge-up} {
                width: 75%;
                margin-left: 25%;
            }
        }
        .pensio_payment_form_label_cell {
            margin-bottom: 0;
        }
    }
    .cvc_row .pensio_payment_form_input_cell {
        margin-bottom: 0.2rem;
    }
    .cardholdername_row {
        margin-top: 1rem;
        .pensio_payment_form_input_cell {
            margin-bottom: 0;
        }
    }
    .submit_row {
        display: none;
    }
}

.booking-details {
    & .label {
        color: #6E7477;
        font-weight: bold;
        margin-top: 1em;
        @media #{$medium-up} {
            margin-top: 0em;
        }
    }
}
