﻿$include-dfds-overlay-classes: true !default;

$overlay-background-color: #000 !default;
$overlay-background-opacity: 0.4 !default;

@include exports("dfds-overlay") {
    @if $include-dfds-overlay-classes {

        .overlay {

            background-color: #000;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 50;
            opacity: 0; 
            width: 0;
            height: 0;
            visibility: hidden;

            &.active {

                visibility: visible;
                opacity: 0.4;
                width: auto;
                height: auto;
            }

            &.active-full {

                visibility: visible;
                opacity: 0.4;
                width: auto;
                height: auto;
                display: block;
                z-index: 100;
            }

            @media #{$large-up} {

                &.active {

                    display: none !important;
                }

                display: none;
            }
        }
    }
}