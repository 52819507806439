﻿.meals-container {

    .flow-link {

        font-family: $font-family-dfds-bold;
        text-transform: uppercase;
    }

    .no-result {

        text-align: center;

        h1, h2 {

            font-size: 1em;
            text-transform: capitalize;
        }
    }

    .meals-header {

        position: relative;

        span {

            display: block;
            
            @media #{$medium-up} {

                position:absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .group-panel {

        border: 1px solid #CACACA;
        
        padding-bottom: ($column-gutter / 4);

        .group-header {

            background-color: #e7ecef;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            text-align: left;
        }

        .group-item {

            border-bottom: 1px solid #E5E5E5;
            padding-top: ($column-gutter / 4);
            padding-bottom: ($column-gutter / 2);

            &:last-of-type {

                border-bottom: 0;
                padding-bottom: 0;
            }

            @media #{$medium-up} {

                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .option-name, .option-price, .option-text, .option-radio {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .option-checkbox {
        padding-top: 0.15em;   
    }

    .option-time, .option-price, .option-count, .option-checkbox {
        
        text-align: right;

        @media #{$medium-up} {

                text-align: left;
        }
    }

    .option-checkbox input[type=checkbox] + input[type=hidden] + label
    {
        margin-right: -0.5em;
    }

    .option-time select, .option-count select {

        width: 6em;

        @media #{$medium-up} {

            width: 100%;
        }
    }
    input.visible-checkbox{
        display:block;
    }

}

.limited-size-modal {
    @media #{$medium-up} {
        max-width: 30em !important;
    }
    min-height:0;
}

.mandatory-checkbox + input[type=hidden] + label:before,
.mandatory-checkbox + input[type=hidden] + label:after {
    opacity: .5;
    cursor: default;
}