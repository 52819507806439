$include-dfds-buttons-classes: true !default;

@mixin button-style {
    width: 100%;
    padding: 0.5em 0.5em;    
    border:none;
    border-radius: 1px;
    background: #F2F2F2;    
    text-align: center;
    cursor: pointer;
    display: inline-block;

    &:focus {

        outline: none;        
    }
}

@include exports("dfds-buttons") {
    @if $include-dfds-buttons-classes {
        
        .preferred-action {
            
            @include button-style;

            background-color: $dfds-color-action !important;
            font-family: $font-family-dfds-bold;
            font-weight: bold;
            text-transform: uppercase;
            color: $dfds-color-primary;            
    
            &:hover, &:focus {
                
                background-color: $dfds-color-action-hover !important;
                color: $dfds-color-primary;
            }

            &[disabled] {

                cursor: default;
                color: #808080 !important;
                background-color: #DDD !important;             
            }
        }

        .previous-action {
            @include button-style;
            background-color: #E8F4FB;
            color: #68B2E3;
            font-family: $font-family-dfds-bold;
            font-weight: bold;
            text-transform: uppercase;

        }

        .secondary-action {
            @include button-style;
            background-color: #68B2E3;
            color: #ffffff;
            font-family: $font-family-dfds-bold;
            font-weight: bold;
            text-transform: uppercase;
        }

        .cancel {
            @include button-style;
            background-color: #EAEDEE;
            color: #68B2E3;
            font-weight: normal;
            font-family: $font-family-dfds-regular;
            text-transform: none;
        }

        @media #{$medium-up} {
            .secondary-action-form .secondary-action {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .default-action {
            @include button-style;
            color: #000000;
        }
        
        .disabled-action {

            @include button-style;
            cursor: default;

            background-color: #DDD !important;
        }
    }
}
