﻿$include-dfds-text-classes: true !default;

@include exports("dfds-text") {
    @if $include-dfds-text-classes {

        h1, h2, h3, h4, h5, h6 {
            font-family: $font-family-dfds-bold;
            font-weight: normal;
            line-height: 1.25;
            margin: 0;
            padding: 0;
            color: #002B45;
        }

        // Added to support new label styling from shared components without styling other labels
        .shared label {
            font-family: $font-family-dfds-regular;
            font-weight: bold;
        }

        h1 {
            font-size: 30px;
            margin-bottom: 0.5em;
            margin-top: 0;
        }

        h1.middle {
            margin-top: 0.5em;
        }

        h2 {
            font-size: 20px;
            margin-bottom: 0.5em;
            margin-top: 1em;
        }

        h2.top {
            margin-top: 0;
        }

        h3 {
            font-size: 1.3em;
            margin-bottom: 1em;
        }

        h4 {
            font-size: 16px;
        }

        h5 {
            font-size: 16px;
            color: #4D4E4C;
            display: inline;
            margin: 0;
        }

        .disabled {
            & h1, & h2, & h3, & h4, & h5, & h6 {
                opacity: 0.5; //Modern browsers
                filter: alpha(opacity=50); // IE 5, 6, 7, 8 & 9
            }
        }
    }
}
