$include-dfds-fonts-classes: true !default;

$font-path: "https://unpkg.com/@dfds-ui/fonts@0.0.42/fonts/" !default;

$font-family-dfds-bold:     'DFDS-bold', 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-light:    'DFDS-light', 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-regular:  'DFDS-regular', 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-italic:   'DFDS-italic', 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-black:    'DFDS-black', 'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-default:  'Segoe UI', 'Lucida Grande', 'Helvetica Neue', Arial, sans-serif !default;
$font-family-dfds-forms:    'Segoe UI', 'Helvetica Neue', Arial, sans-serif !default;

$font-weight-light:     300 !default;
$font-weight-normal:    400 !default;
$font-weight-bold:      700 !default;

$font-size-base:        16px !default;
$font-size-smaller:     0.85rem !default;
$font-size-bigger:      1.1rem !default;

@include exports("dfds-fonts") {
    @if $include-dfds-fonts-classes {

        @font-face {
            font-family: 'DFDS-bold';
            src: url('#{$font-path}DFDS-Bold.woff') format('woff');
            font-style: normal;
        }

        @font-face {
            font-family: 'DFDS-light';
            src: url('#{$font-path}DFDS-Light.woff') format('woff');
            font-weight: $font-weight-light;
            font-style: normal;
        }

        @font-face {
            font-family: 'DFDS-regular';
            src: url('#{$font-path}DFDS-Regular.woff') format('woff');
            font-style: normal;
        }

        @font-face {
            font-family: 'DFDS';
            src:url('#{$font-path}DFDS-Regular.woff2') format('woff2'),
              url('#{$font-path}DFDS-Regular.woff') format('woff');
            font-weight: 400;
            font-style: normal;
            font-display: fallback;
        }

        @font-face {
            font-family: 'DFDS';
            src:url('#{$font-path}DFDS-Italic.woff2') format('woff2'),
              url('#{$font-path}DFDS-Italic.woff') format('woff');
            font-weight: 400;
            font-style: italic;
            font-display: fallback;
        }

        @font-face {
            font-family: 'DFDS';
            src:url('#{$font-path}DFDS-Bold.woff2') format('woff2'),
              url('#{$font-path}DFDS-Bold.woff') format('woff');
            font-weight: 700;
            font-style: normal;
            font-display: fallback;
        }

        @font-face {
            font-family: 'DFDS';
            src:url('#{$font-path}DFDS-Light.woff2') format('woff2'),
              url('#{$font-path}DFDS-Light.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: fallback;
        }

    }
}
