﻿.main-area,
.itinerary {
  @include locked-size(400px, $column-gutter, $large-up, large);
}

.main-area {
  padding-left: 0;
  padding-right: 0;

  @media #{$medium-up} {
    padding-left: ($column-gutter / 2);
    padding-right: ($column-gutter / 2);
  }

  &.wrapper {
    .content {
      position: relative;
      background-color: $dfds-color-primary;
      margin-bottom: ($column-gutter / 2);

      @media #{$large-up} {
        margin-bottom: $column-gutter;
      }
    }

    .content-amendment {
      position: relative;
      padding-bottom: 0.8333rem;
      margin-bottom: ($column-gutter / 2);

      @media #{$large-up} {
        margin-bottom: $column-gutter;
      }
    }
  }
}

.content-container {
  padding: $column-gutter;
  padding-bottom: 0;
}

.loader-label {
  color: rgb(77, 78, 76);
  text-align: center;
  position: fixed;
  font-family: "DFDS-Regular";
  font-weight: 400;
  display: block;
  top: 55%;
  right: 0;
  left: 0;
  z-index: 300;
  padding-top: 2rem;
  font-size: 17px;
}

@media only screen and (min-width: 64.063em) {
    .loader-label {
      padding-top: 2rem;
    }
}

.loader {
  z-index: 300;
  color: inherit;

  .ship {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    margin-top: -40px;
    margin-left: -40px;
    animation: bop 0.58s ease-in-out alternate infinite;
    width: 80px;
    height: 80px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .waves {
    top: 50%;
    left: 50%;
    position: fixed;
    width: 146px;
    height: 20px;
    color: inherit;
    margin-left: -73px;
    margin-top: 13px;
    animation: sail 0.8s linear infinite;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  @keyframes bop {
    to {
      transform: translateY(-3px);
    }
  }

  @keyframes sail {
    to {
      transform: translateX(-30px);
    }
  }
}

.overlay-loading {
  background-color: #eef0f1;
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  z-index: 299;
}

// TODO: Move this to framework and file
@mixin is-loading-content() {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: #ccc;
  opacity: 0.3;
}

.default-cursor {
  cursor: default !important;
}

// Hide border to make it look the same on dotcom
#Coi-Renew {
  border: none !important;
}
