.fares-container {

    h2 {
        margin-top: 0;
    }

    sbw-prev-next > a {                
        word-break: keep-all;
    }
}

.fares-grid-container {
    @media #{$medium-up} {
        border: 1px solid #CACACA;
    }
    margin-bottom: 0.5em;
    width: 100%;

    .grid-header {
        padding-top: ($column-gutter / 2);
        padding-bottom: ($column-gutter / 2);
        background-color: #E7ECEF;

        .fares-direction-header {
            font-weight: bold;
        }        

        .grid-header-date {
            font-weight: normal;
            text-align: left;

            @media #{$medium-up} {
                font-weight: bold;
                text-align: right;
            }
        }
    }

    .grid-description {
        padding-top: ($column-gutter / 2);
        padding-bottom: ($column-gutter / 2);
        word-break: break-word;
    }

    .data-table {
        .matrix-0 {
            background-color: #D2E5EC;
            border-color: #D2E5EC;
        }

        .matrix-1 {
            background-color: #9BD7ED;
            border-color: #9BD7ED;
        }

        .matrix-2 {
            background-color: #5EBFE2;
            border-color: #5EBFE2;
        }

        .data-header {
            padding: ($column-gutter / 2);
            padding-top: 0;
            border-bottom: 1px solid #CACACA;
            font-weight: bold;
            color: #6D6F71;
        }

        .data-panel {

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                border-bottom: 0;
            }

            .data-panel-header {
                position: relative;
                padding: 1.2rem ($column-gutter / 2);
                border-bottom: 1px solid #ddd;
                border-top: 1px solid #E5E5E5;
                font-weight: bold;

                span {
                    display: block;
                    font-weight: normal;

                    @media #{$medium-up} {
                        display: inline;
                        position: absolute;
                        right: ($column-gutter / 2);
                    }
                }
            }

            .data-items {
                background-color: #F2F2F2;
                
                font-size: 0;

                .data-item {
                    display: inline-block;
                    font-size: 16px;
                    width: 100%;
                    vertical-align: top;

                    .data-content {
                        border-bottom: 1px solid #ddd;
                        border-radius: 2px;
                        position: relative;
                        width: 100%;
                        padding-bottom: 0.116rem;
                        overflow: hidden;


                        .data-content-column {
                            float: left;
                            text-align: right;

                            &.time {
                                padding-top: 0.5rem;
                                padding-bottom: 0.6rem;
                                font-weight: bold;
                                text-align: left;
                            }

                            .price {
                                position: relative;
                                border-radius: 2px;
                                z-index: 10;
                                display: inline-block;
                                cursor: pointer;
                                word-wrap: break-word;
                                text-align: center;
                                overflow: hidden;
                                min-width: 6em;

                                input {
                                    display: none;
                                }

                                &.lowest {
                                    border-left: 0.3em solid #FFCB0D !important;
                                    padding-left: 0.9em;
                                }

                                &.mid {
                                    border-left: 0.3em solid #898989 !important;
                                    padding-left: 0.9em;
                                    background-color: #D5D5D5 !important;
                                    opacity: 0.7;
                                }
                            }
                        }
                    }

                    &:last-child {

                        .data-content {
                            border-bottom: none;
                        }
                    }

                    .hide-prices-for-medium-down {

                        .price {
                            display: none;
                        }

                        .price.show {
                            display: inline-block;
                        }
                    }

                    @media #{$large-up} {

                        .hide-prices-for-medium-down {

                            .price {
                                display: inline-block;
                            }
                        }
                    }



                    &.selected {

                        .price.selected {
                            font-weight: bold;
                            color: #FFF;
                            text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
                            background: #F6861F;
                            
                        }
                    }
                }
            }
        }

        .data-options {
            padding: ($column-gutter / 2) ($column-gutter / 4);

            div {

                input {
                    display: none;
                    margin: 5px auto 0 auto;
                }

                label {
                    width: 100%;
                    position: relative;
                    display: block;
                    padding-left: 0.4em;
                    padding-right: 0.4em;
                    text-align: center;
                    border-radius: 2px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    &:after {
                        padding: 0;
                        border: none;
                        
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    .grid-bottom {
        padding-top: ($column-gutter);
        padding-bottom: ($column-gutter / 2);
        border-top: 1px solid #DDD;
        background-color: #FFF;
        font-size: 0.91em;

        > div {
            margin-bottom: ($column-gutter / 2);
            text-align: center;

            &:last-child {
                margin-bottom: 0;
            }

            @media #{$medium-up} {
                margin-bottom: 0;
                text-align: left;

                &.text-right {
                    text-align: right;
                }
            }
        }

        a:after,
        a:before {
            color: #005184;
            font-size: 0.8em;
        }
    }
}
