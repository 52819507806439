﻿//
// Global Foundation Mixins
//

// @mixins
//
// We use this to control border radius.
// $radius - Default: $global-radius || 4px
@mixin radius($radius:$global-radius) {
    @if $radius {
        border-radius: $radius; 
    }
}

// @mixins
//
// We use this to create equal side border radius on elements.
// $side - Options: left, right, top, bottom
@mixin side-radius($side, $radius:$global-radius) {
    @if ($side == left or $side == right) {
        -webkit-border-bottom-#{$side}-radius: $radius;
        -webkit-border-top-#{$side}-radius: $radius;
        border-bottom-#{$side}-radius: $radius;
        border-top-#{$side}-radius: $radius;
    } @else {
        -webkit-#{$side}-left-radius: $radius;
        -webkit-#{$side}-right-radius: $radius;
        border-#{$side}-left-radius: $radius;
        border-#{$side}-right-radius: $radius;
    }
}

// @mixins
//
// We can control whether or not we have inset shadows edges.
// $active - Default: true, Options: false
@mixin inset-shadow($active:true) {
    

    @if $active { 

        &:active {
            
        } 
    }
}

// @mixins
//
// We use this to add transitions to elements
// $property - Default: all, Options: http://www.w3.org/TR/css3-transitions/#animatable-properties
// $speed - Default: 300ms
// $ease - Default:ease-out, Options: http://css-tricks.com/almanac/properties/t/transition-timing-function/
@mixin single-transition($property:all, $speed:300ms, $ease:ease-out) {
    transition: $property $speed $ease;
}

// @mixins
//
// We use this to add box-sizing across browser prefixes
@mixin box-sizing($type:border-box) {
    -webkit-box-sizing: $type; // Android < 2.3, iOS < 4
        -moz-box-sizing: $type; // Firefox < 29
            box-sizing: $type; // Chrome, IE 8+, Opera, Safari 5.1
}

// @mixins
//
// We use this to create isosceles triangles
// $triangle-size - Used to set border-size. No default, set a px or em size.
// $triangle-color - Used to set border-color which makes up triangle. No default
// $triangle-direction - Used to determine which direction triangle points. Options: top, bottom, left, right
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: inset $triangle-size;

    @if ($triangle-direction == top) {
        border-color: $triangle-color transparent transparent transparent;
        border-top-style: solid;
    }

    @if ($triangle-direction == bottom) {
        border-color: transparent transparent $triangle-color transparent;
        border-bottom-style: solid;
    }

    @if ($triangle-direction == left) {
        border-color: transparent transparent transparent $triangle-color;
        border-left-style: solid;
    }

    @if ($triangle-direction == right) {
        border-color: transparent $triangle-color transparent transparent;
        border-right-style: solid;
    }
}

// @mixins
//
// We use this to create the icon with three lines aka the hamburger icon, the menu-icon or the navicon
// $width - Width of hamburger icon in rem
// $left - If false, icon will be centered horizontally || explicitly set value in rem
// $top - If false, icon will be centered vertically || explicitly set value in rem
// $thickness - thickness of lines in hamburger icon, set value in px
// $gap - spacing between the lines in hamburger icon, set value in px
// $color - icon color
// $hover-color - icon color during hover
// $offcanvas - Set to true of @include in offcanvas
@mixin hamburger($width, $left, $top, $thickness, $gap, $color, $hover-color, $offcanvas) {

    span {

        &:after {

            content: "";
            position: absolute;
            display: block;
            height: 0;

            @if $offcanvas {

                @if $top {
                    top: $top;
                } @else {
                    top: 50%;
                    margin-top: (-$width/2);
                }

                @if $left {
                    left: $left;
                } @else {
                    left: ($tabbar-menu-icon-width - $width)/2;
                }

            } @else {

                top: 50%;
                margin-top: -($width/2);
                #{$opposite-direction}: $topbar-link-padding;
            }

            box-shadow:
                0 0 0 $thickness $color,
                0 $gap + $thickness 0 $thickness $color,
                0 (2 * $gap + 2*$thickness) 0 $thickness $color;

            width: $width;
        }

        &:hover {

            &:after {

                box-shadow:
                    0 0 0 $thickness $hover-color,
                    0 $gap + $thickness 0 $thickness $hover-color,
                    0 (2 * $gap + 2*$thickness) 0 $thickness $hover-color;
            }
        }
    }
}

// We use this to do clear floats
@mixin clearfix {
    &:before, &:after { content: " "; display: table; }
    &:after { clear: both; }
}

// @mixins
//
// We use this to add a glowing effect to block elements
// $selector - Used for selector state. Default: focus, Options: hover, active, visited
// $fade-time - Default: 300ms
// $glowing-effect-color - Default: fade-out($primary-color, .25)
@mixin block-glowing-effect($selector:focus, $fade-time:300ms, $glowing-effect-color:fade-out($primary-color, .25)) {
    transition: box-shadow $fade-time, border-color $fade-time ease-in-out;

    &:#{$selector} {
        
        border-color: $glowing-effect-color;
    }
}

// @mixins
//
// We use this to translate elements in 2D
// $horizontal: Default: 0
// $vertical: Default: 0
@mixin translate2d($horizontal:0, $vertical:0) {
    transform: translate($horizontal,$vertical)
}

// @mixins
//
// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

// @mixins
//
// Turns off the element-invisible effect.
@mixin element-invisible-off {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
}