﻿.header-item {

    // Should this maybe be general? 

    background-color: $dfds-color-brand;
    color: $dfds-color-primary;
    font-family: $font-family-dfds-bold;
    font-size: $font-size-smaller;
    text-transform: uppercase;
    padding: 0 0.5em;
    display: inline-block;
    height: 2em;
    line-height: 2em;

    @include dfds-triangle(#005184, 2em);
}