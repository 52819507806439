﻿$include-dfds-icon-classes: true !default;

$icon-font-path: "assets/" !default;

@include exports("dfds-icons") {
    @if $include-dfds-icons-classes {

        @font-face {
            font-family: 'icomoon';
            src:url('#{$icon-font-path}icomoon.eot?gvto4');
            src:url('#{$icon-font-path}icomoon.eot?#iefixgvto4') format('embedded-opentype'),
                url('#{$icon-font-path}icomoon.woff?gvto4') format('woff'),
                url('#{$icon-font-path}icomoon.ttf?gvto4') format('truetype'),
                url('#{$icon-font-path}icomoon.svg?gvto4#icomoon') format('svg');
            font-weight: normal;
            font-style: normal;
        }

        %iconfont {
            font-family: 'icomoon';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        $iconlist:
        ("icon-location", "\e611"),
        ("icon-photo", "\e60f"),
        ("icon-photo2", "\e610"),
        ("icon-ship", "\e601"),
        ("icon-arrow-thin-down", "\e607"),
        ("icon-arrow-right", "\e608"),
        ("icon-menu", "\e603"),
        ("icon-arrow-thin-right", "\e609"),
        ("icon-arrow-left", "\e60a"),
        ("icon-arrow-thin-top", "\e60b"),
        ("icon-home", "\e60c"),
        ("icon-lock", "\e60d"),
        ("icon-lock-wrap a", "\e60d"),
        ("icon-search", "\e602"),
        ("icon-checkmark", "\e612"),
        ("icon-minus22", "\e613"),
        ("icon-plus22", "\e614"),
        ("icon-minus", "\e615"),
        ("icon-plus", "\e616"),
        ("icon-minus2", "\e617"),
        ("icon-plus2", "\e618"),
        ("icon-arrow-thin-left", "\e60e"),
        ("icon-mail", "\e619"),
        ("icon-unlocked", "\e61c"),
        ("icon-loop", "\e61e"),
        ("icon-user", "\e61f"),
        ("icon-bubbles", "\e604"),
        ("icon-phone", "\e600"),
        ("icon-question", "\e625"),
        ("icon-plus23", "\e623"),
        ("icon-light-bulb", "\e624"),
        ("icon-info", "\e626"),
        ("icon-info2", "\e627"),
        ("icon-arrow-up-right", "\e628"),
        ("icon-zoomin", "\e629");


        @each $icon in $iconlist {
            %#{nth($icon,1)},
            .#{nth($icon,1)}-after:after{
                @extend %iconfont;
                content: nth($icon, 2);
                margin-left: 5px;
            }
            .#{nth($icon,1)}:before {
                @extend %iconfont;
                content: nth($icon, 2);
                margin-right: 5px;
            }
        }
    }
}
