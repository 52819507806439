﻿$include-dfds-tip-classes: true !default;

@include exports("dfds-tip") {
    @if $include-dfds-tip-classes {

        .sbw_info_box {

            display: none; /* This is to avoid flickering */
        }

        .tip {

            cursor: pointer;

            &:after {

                margin-left: 0.4em;
                vertical-align: middle;
                color: #90B3C9;
                top: -0.1em; // This is to make it more aligned with the text/label
                position: relative;
            }
        }

        .tip-details {

            display: none;
            z-index: 200;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            padding: 0;
            
            &.active {

                display: table;
            }

            .outer {

                display: table-cell;
                margin: 0;
                padding: 0;

                .inner {
                    background-color: #fff;
                    max-width: 600px;
                    margin: 0 ($column-gutter/2);
                    padding: $column-gutter;
                    display: inline-block;
                    text-align: left;
                    overflow: auto;
                    max-height: 100%;
                    position: fixed;

                    .close-button {

                        position: absolute;
                        right: 0;
                        top: 0;
                        line-height: 100%;
                        font-size: 1.8em;
                        color: #788288;
                        background-color: #ECECEC;
                        padding: 0 0.3em 0.2em 0.4em;
                        cursor: pointer;
                    }

                    h1,h2,h3,h4 {

                        margin-top: 0;
                    }

                    p,div {

                        &:last-child {

                            margin-bottom: 0;
                        }
                    }
                }
                     
                @media #{$medium-up} {

                    .inner {
                        top: 10vh;
                        left: 50%;
                        margin-left: -300px;
                        position: fixed;
                        max-height: 80vh;
                    }
                }
            }
        }
    }
}