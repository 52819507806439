﻿$include-dfds-header-classes: true !default;

$header-expect-wrapper: true !default;

$header-small-padding: 0.85em !default;
$header-large-padding: 1.25em !default;

$header-logo-small-width: 150px !default;
$header-logo-large-width: auto !default;

@include exports("dfds-header") {
    @if $include-dfds-header-classes {

    $include-wrapper: if($header-expect-wrapper, ".wrapper", "");

        .header#{$include-wrapper} {

            background-color: $dfds-color-primary;
            padding-top: $header-small-padding;
            padding-bottom: $header-small-padding;
            position: relative;

            &.fixed {
                position: fixed;
                width: 100%;
                top: 0;
                z-index: 5003;
                background-color: $dfds-color-primary;
                padding-top: $header-small-padding;
                padding-bottom: $header-small-padding;

                @media only screen and (max-width: 1091px){ 
                    padding-top: 0.55em;
                    padding-bottom: 0.55em;
                }
            }

            @media only screen and (max-width: 1091px){
                padding-top: 0.55em;
                padding-bottom: 0.55em;
            }

            header {

                .logo {

                    img {

                        display: block;
                        width: $header-logo-small-width;
                        height: auto;

                        @media #{$large-up} {
                            width: $header-logo-large-width;
                        }
                    }
                }

                #topLogoLink {
                    img {
                        height: 2rem;
                    }
                }

                .navigation-toggle {

                    font-size: 1.34em; // this is to scale the icon
                    height: 1.25em; // this is to eliminate space around icon
                    position: absolute;
                    right: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }

        .top-box {
            background-color:#002b45;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 124px;

            @media only screen and (max-width: 768px){
                height: 80px;                
            }

            h1 {
                font-size: 48px;
                color: white;
                margin: 32px 0;
                font-family: 'DFDS';
                font-weight: 300;
                padding-left: .83333rem;

                @media only screen and (max-width: 1320px){
                    padding-left: 1.66666rem;
                }
                
                @media only screen and (max-width: 768px){
                    font-size: 32px;                
                    margin: 20px 0;
                }
                @media only screen and (max-width: 640px) {
                    padding-left: .83333rem;
                }
                  
            }
        }

    }
}
