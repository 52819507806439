﻿.hotels-container {

    .flow-link {
        font-family: $font-family-dfds-bold;
        text-transform: uppercase;
    }

    .no-result {
        text-align: center;

        h1, h2 {
            font-size: 1em;
            text-transform: capitalize;
        }
    }

    .hotels-header {
        position: relative;

        span {
            display: block;

            @media #{$medium-up} {
                position:absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .group-panel {
        border: 1px solid #CACACA;


        .group-header {
            background-color: #e7ecef;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            text-align: left;

            &:not(:first-of-type) {
                border-top: 1px solid #CACACA;
            }
        }

        .room-type-header {
            color: #757575;
            font-weight: bold;
            padding-bottom: ($column-gutter / 2);
            padding-top: ($column-gutter / 2);
            padding-left: 0.8em;
        }

        .active {
                 background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogPGc+DQogIDx0aXRsZT5MYXllciAxPC90aXRsZT4NCiAgPHBhdGggdHJhbnNmb3JtPSJyb3RhdGUoMTgwIDI0LDIzLjgzNDk5OTA4NDQ3MjY1NikgIiBpZD0ic3ZnXzIiIGQ9Im0xNC44MywxNi40Mmw5LjE3LDkuMTdsOS4xNjk5OTgsLTkuMTdsMi44MzAwMDIsMi44M2wtMTIsMTJsLTEyLC0xMmwyLjgzLC0yLjgzeiIvPg0KICA8cGF0aCBpZD0ic3ZnXzMiIGZpbGw9Im5vbmUiIGQ9Im0wLC0wLjc1aDQ4djQ4aC00OGwwLC00OHoiLz4NCiA8L2c+DQo8L3N2Zz4=");
                 background-repeat: no-repeat;
                 background-position: right 0.5em center;
                 background-size: 25px;
        }

        .inactive {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+");
            background-repeat: no-repeat;
            background-position: right 0.5em center;
            background-size: 25px;
        }

        .group-item {
            border-bottom: 1px solid #E5E5E5;
            padding-top: ($column-gutter / 2);
            padding-bottom: ($column-gutter / 2);
            padding-left: 0.8em;

            &:last-of-type {
                border-bottom: 0;
                padding-bottom: 0;
            }

            @media #{$medium-up} {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .option-name, .option-price, .option-time, .option-text, .option-radio {
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .option-text {
        font-weight: bold;
    }

    .option-time, .option-price, .option-count {
        text-align: right;
    }

    .option-time select, .option-count select {
        width: 100%;
    }

    .adult_icon { background:url(../images/adult.png) repeat-x; height:11px; display:inline-block; margin-right: -2px; }
    .child_icon { background:url(../images/child.png) repeat-x; height:11px; display:inline-block }
    .sbw_blue_button { background: #205C8D url(../images/button-blue.png) repeat-x;  border-style: none; }
}
