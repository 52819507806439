﻿
.login-container {

    .login-box {

        border-bottom: 1px solid #EDEDED;
        margin-bottom: $column-gutter;
        padding-bottom: $column-gutter;
    }

    .register-box {

        margin-bottom: $column-gutter;
    }

    @media #{$xlarge-up} {

        .login-box {

            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
            padding-right: 2.99rem;
        }

        .register-box {

            border-left: 1px solid #EDEDED;
            padding-left: 2.99rem;
        }
    }
}

.reset-password-container {

    padding-bottom: ($column-gutter/2);

    p {

        margin-top: 0;
    }
}

.select-address-container {
    
    p {

        margin-top: 0;
    }

    .addresses-container {

        margin-bottom: $column-gutter;

        .field {

              margin-bottom: 1em;
              border-top: 1px solid #ECECEC;
              padding-top: 1em;
        }
    }

    @media #{$medium-up} {

        .field.submit {
            
            margin-bottom: 0;
        }
    }
}