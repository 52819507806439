﻿.flow-container {
    padding: $column-gutter;
}
@media #{$small-only} {
    .flow-container {
        padding: $column-gutter / 2;
    }
}

.flow-breadcrumb {
    margin-bottom: ($column-gutter/2);
    border-bottom: 1px solid #EDEDED;
    padding-bottom: 1rem;

    @media #{$large-up} {

        margin-bottom: $column-gutter;
    }

    ul {

        margin: 0;
        padding: 0;
        overflow: auto;
        list-style: none;

        li {
        
            margin: 0 0.9em 0 0;
            padding: 0;
            float: left;

            @media #{$large-up} {


                &:after {

                    content: "\e609";
                    color: $dfds-color-text-light;
                    font-family: 'icomoon';
                    font-size: 0.6em;
                    margin-left: 1em;
                }
            }

            &:last-child {

                margin-right: 0;

                &:after {

                    content: "";
                    display: none;
                }
            }

            a, span {

            
            }

            &.selected  {

                color: $dfds-color-action-hover;
                display: inherit !important;
            }
        }
    }
}

.disclaimer {
    
    border-top: 1px solid #EDEDED;
    padding-top: $column-gutter/2;
}