﻿$include-dfds-footer-classes: true !default;

$footer-expect-wrapper: true !default;

$footer-small-padding: 2em !default;
$footer-large-padding: 3.25em !default;

@include exports("dfds-footer") {
    @if $include-dfds-footer-classes {

    $include-footer-wrapper: if($footer-expect-wrapper, ".wrapper", "");

        .footer#{$include-footer-wrapper} {

            background-color: #002b45;
            padding-top: $footer-small-padding;
            padding-bottom: $footer-small-padding;

            @media #{$large-up} {

                padding-top: $footer-large-padding;
                padding-bottom: $footer-large-padding;
            }

            footer {

                .container {

                    border-top: solid 1px $dfds-color-brand-light;
                    position: relative;

                    .icon {

                        font-size: 3.5em;
                        color: $dfds-color-primary;
                        position: absolute;
                        left: 0;
                        top: -0.68em;
                    }

                    ul {

                        float: none;
                        list-style: none;
                        margin-top: 10px;
                        margin-bottom: 0;
                        padding: 0;

                        @media #{$large-up} {
            
                            float: right;
                        }

                        li {

                            float: left;
                            margin-right: 0.6em;

                            &:last-child {

                                margin-right: 0;

                                a, span {

                                    color: $dfds-color-primary;
                                }
                            }

                            a, span {

                                display: block;
                                color: $dfds-color-primary;
                                font-size: $font-size-smaller;
                                padding: 0 0 0 0;
                            }

                            a {

                                color: $dfds-color-brand-light;

                                &:hover {

                                    color: $dfds-color-primary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}