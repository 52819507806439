﻿$include-dfds-navigation-classes: true !default;

$navigation-expect-wrapper: true !default;

$navigation-item-margin: 1.25em !default;
$navigation-item-padding-large: 1.25em 0 1.25em 0 !default;
$navigation-item-padding-small: 0.9em 0 0.9em 0 !default;

$navigation-fontsize-small: 0.9em !default;
$navigation-fontsize-large: 1em !default;


@include exports("dfds-navigation") {
    @if $include-dfds-navigation-classes {

    $include-navigation-wrapper: if($navigation-expect-wrapper, ".wrapper", "");

        .navigation#{$include-navigation-wrapper} {

            background-color: $dfds-color-primary;
            border-top: 1px solid $dfds-color-secondary;
            position: relative; 
            z-index: 60;

            nav {

                font-family: $font-family-dfds-light;

                ul {

                    list-style: none;
                    margin-top: 0;
                    margin-bottom: 0;

                    li {

                        float: none;

                        a {

                            display: block;
                            color: $dfds-color-text;
                            padding: $navigation-item-padding-small;
                            border-bottom: 1px solid $dfds-color-secondary;
                            font-size: $navigation-fontsize-small;

                            &:before {

                                font-family: 'icomoon';
                                speak: none;
                                content: "\e608";
                                float: left;
                                margin: 5px 5px 0 0;
                                font-size: 9px;
                            }
                        }

                        &:last-child {

                            a {

                                border-bottom: 0;
                            }
                        }

                        @media #{$large-up} {

                            float: left;
                            margin-right: $navigation-item-margin;

                            &:last-child {

                                margin-right: 0;
                            }

                            a {

                                display: block;
                                color: $dfds-color-text;
                                padding: $navigation-item-padding-large;
                                font-size: $navigation-fontsize-large;
                                border-bottom: 0;

                                &:before {

                                    content: "";
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &.active {

                display: block !important;
            }
        }
    }
}