﻿.row.passenger-row {

    margin-left: ($column-gutter / 2);
    margin-right: ($column-gutter / 2);
    margin-bottom: ($column-gutter / 2);
    padding-top: ($column-gutter / 2);

    @media #{$xlarge-up} {

        box-shadow: none;
        border-top-width: 0;
        border-bottom-width: 0;
        padding-top: 0;

        .label {
            margin-bottom: ($column-gutter / 2);
        }
    }
}

.payment-choice-box {
    margin-right: ($column-gutter / 2);
    margin-bottom: ($column-gutter / 2);
    border: 1px solid #E2E2E2;
    border-bottom-color: #D5D5D5;
    border-right-color: #D5D5D5;
    
    padding: ($column-gutter ) ($column-gutter ) 0 ($column-gutter / 2) ;
}

.passenger-type {

    font-weight: bold;

    @media #{$xlarge-up} {

        border-bottom: 0;
        padding-top: 0.4em;
        padding-bottom: 0;
        font-weight: normal;
    }
}

.vehicle-type {

    @media #{$xlarge-up} {

        padding-top: 0.4em;
    }
}

.dob-label {
    font-size: 0.7em;
    font-style: italic;
}

#ui-datepicker-div[inputId=passengerDateOfBirth] {
    .ui-datepicker-year {
        transform: scale(.7);
        color: #000;
        option {
            font-size: 0.7em;
        }
    }
}
input.pmask {
    text-align: left;
    font-size: 18px;        
}