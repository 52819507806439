﻿.dynamic-form-fields{
    padding: 0;
    margin: 0;
    list-style-type: none;

    .template{
        display:none;
    }

    .add button{
        background-color: #FFE1C5 !important;
        border-color: #E2B489 !important;
        color: #2D2D2D;
    }

    .item{
        padding: 1em;
        margin-bottom: 1em;
        background-color: #F5F5F5;
        overflow: auto;
        height: 100%;

        .remove{
            float: right;
        }
    } 
}

