﻿.help {

    ul {

        list-style: none;
        margin-top: 1rem;
        margin-bottom: 1rem;

        li {

            float: right;
            margin-left: 1.25em;

            &:last-child {

                margin-left: 0;
            }

            a {
                display: block;
                padding: 0.25rem 0 0.25rem 0;
            }

            span {
                    display: block;
                    padding: 0.25rem 0 0.25rem 0;
            }
        }
    }
}